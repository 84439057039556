module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Amsterdam-Chiropractic","short_name":"Amsterdam-Chiro","start_url":"/","background_color":"#00533e","theme_color":"#00533e","display":"minimal-ui","icon":"src/images/ac-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"61d3b877c2c988b2af87bbbd48ef851b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
